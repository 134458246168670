<template>
  <Form
    :class="['form dynamic-form', submitting ? 'hover:cursor-wait' : '']"
    @submit="onSubmit"
  >
    <component
      v-for="field in schema.fields"
      :is="field.type === 'fieldset' ? 'fieldset' : 'div'"
      :key="field.name"
      :class="[
        field.type === 'fieldset'
          ? 'field-group-container grid gap-4 md:gap-y-0 grid-cols-1 md:grid-cols-2'
          : 'single-field-container',
      ]"
    >
      <div v-if="field.type === 'fieldset'" v-for="field in field.fields">
        <Field
          :id="field.id"
          class="w-full disabled:opacity-50 disabled:cursor-wait"
          :key="field.name"
          :as="field.as"
          :name="field.name"
          :placeholder="field.label"
          :type="field.type"
          :rules="field.rules"
          :disabled="submitting"
          :autocomplete="field.autocomplete"
        />
        <ErrorMessage :name="field.name" class="leading-none" />
      </div>
      <div v-else>
        <Field
          :id="field.id"
          class="w-full disabled:opacity-50 disabled:cursor-wait"
          :key="field.name"
          :as="field.as"
          :name="field.name"
          :placeholder="field.label"
          :type="field.type"
          :rules="field.rules"
          :disabled="submitting"
          :autocomplete="field.autocomplete"
        />
        <ErrorMessage :name="field.name" class="leading-none" />
      </div>
    </component>
    <span id="manual-error-message" role="alert"></span>
    <Field type="hidden" name="formId" :value="schema.id" />
    <button
      type="submit"
      :class="[
        'button-blue w-auto text-center button button-1  button-medium !inline-block w-fit mx-auto',
        submitting ? 'cursor-wait opacity-50' : 'cursor-pointer',
      ]"
      :disabled="submitting"
    >
      {{ schema.submit.buttonText ?? "Submit" }}
    </button>
  </Form>
</template>

<style>
.form.dynamic-form {
  span[role="alert"] {
    @apply block text-red-500 leading-none text-xs py-1;
  }
  #manual-error-message {
    @apply -mt-4;
  }

  .field-group-container {
  }
  .single-field-container {
  }
}
</style>

<script setup>
import { Form, Field, ErrorMessage } from "vee-validate";

const props = defineProps({
  schema: {
    type: Object,
    required: true,
  },
  submitting: Boolean,
});

const onSubmit = (values) => {
  emit("form-submit", values);
};

const emit = defineEmits(["form-submit"]);
</script>
